.edit-form .sub-title {
}

.edit-form .sub-title h4 {
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  /* identical to box height */
  color: #555555;
  margin-bottom: 25px;
}

.edit-form .sub-title p {
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */
  color: #2e6adf;
  margin-bottom: 14px;
}

.elements-list {
  margin-bottom: 14px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.elements-list a {
  margin-bottom: 10px;
  margin-right: 15px;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height */
  text-align: center;
  color: #333333 !important;
  padding: 10px;
  border: 1px solid #aaaaf6;
  box-sizing: border-box;
  border-radius: 5px;
}

.addnew-btn {
}

.addnew-btn .btn.btn-addnew {
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  color: #848484;
  padding: 17px 34px;
  min-width: 250px;
  background: #ffffff;
  border: 0.6px solid #e1e1e1;
  box-sizing: border-box;
  /* 0 */
  box-shadow: 0px 4px 4px rgba(225, 225, 225, 0.25);
  border-radius: 3px;
}

.addnew-btn .btn.btn-addnew img {
  margin-right: 10px;
}
.elements-list a.highlight {
  background: #2e6adf !important;
  color: #fff !important;
  border: 1px solid #2e6adf !important;
}
.addnew-section {
  min-width: 270px;
  margin-bottom: 30px;
}

.addnew-section .section-card {
  background: #ffffff;
  border: 0.6px solid #e1e1e1;
  box-sizing: border-box;
  /* 0 */
  box-shadow: 0px 4px 4px rgba(225, 225, 225, 0.25);
  border-radius: 2px;
}

.addnew-section .section-card .section-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  height: 50px;
}

.addnew-section .section-card .section-title .form-control.is-filled {
  border: none;
  box-shadow: none;
  /* pointer-events: none; */
}

.addnew-section .section-card .section-title .hide {
  display: none;
}

.addnew-section .section-card .section-title .form-control {
  font-weight: normal;
  font-size: 14px;
  color: #343434;
  margin: 0;
  border: none;
  border-bottom: 1px solid #cfcfcf;
  padding: 0;
  height: 34px;
  line-height: 34px;
}

.addnew-section .section-card .section-title .form-control::placeholder {
  line-height: 34px;
}

.addnew-section .section-card .section-title a {
  margin-left: 14px;
}

.addnew-section .section-card .section-content {
}

.addnew-section .section-card .section-content .flex-box {
  background: #fafafa;
  border-top: 1px solid #e1e1e1;
  border-bottom: 1px solid #e1e1e1;
  padding: 9px;
  justify-content: center;
}

.addnew-section .section-card .section-content .checkbox-block {
  padding: 15px 0;
}

.addnew-section
  .section-card
  .section-content
  .checkbox-block
  .position-relative {
  flex: 1;
  margin: 0;
  text-align: center;
}

.addnew-section .section-card .section-content .flex-box .position-relative {
  text-align: center;
}

.addnew-section
  .section-card
  .section-content
  .checkbox-block
  .position-relative:before {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  width: 1px;
  height: 100%;
  background: #c7c7c7;
}

.addnew-section
  .section-card
  .section-content
  .checkbox-block
  .position-relative:last-child:before {
  background: transparent;
}

.addnew-section
  .section-card
  .section-content
  .checkbox-block
  .position-relative
  label {
}

.addnew-section
  .section-card
  .section-content
  .checkbox-block
  .position-relative
  label:before {
  border-radius: 1.5px;
  width: 16px;
  height: 16px;
  background: rgba(134, 131, 131, 0.24);
  border: none !important;
  padding: 6px;
}

.addnew-section
  .section-card
  .section-content
  .checkbox-block
  .position-relative
  input:checked
  + label:after {
  border-radius: 0px;
  top: 0px;
  left: 0px;
  background: #2e6adf;
  width: 16px;
  height: 16px;
}
.checkbox-block input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: inline-table;
  cursor: pointer;
  -webkit-appearance: checkbox;
}
.addnew-section
  .section-card
  .section-content
  .checkbox-block
  .position-relative
  input:checked
  + label:before {
}

.addnew-section .section-card .section-content .section-body {
  padding: 15px 10px;
}

.addnew-section .section-card .section-content .section-body .elements-card {
  background: #ffffff;
  border: 0.6px solid #e1e1e1;
  box-sizing: border-box;
  /* 0 */
  box-shadow: 0px 4px 4px rgba(225, 225, 225, 0.25);
  border-radius: 2px;
  margin-bottom: 15px;
}

.addnew-section
  .section-card
  .section-content
  .section-body
  .elements-card
  .element-title {
  padding: 15px 10px 7px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.addnew-section
  .section-card
  .section-content
  .section-body
  .elements-card
  .element-title
  p {
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height */
  color: #2e6adf;
  margin: 0;
}

.addnew-section
  .section-card
  .section-content
  .section-body
  .elements-card
  .element-title
  a.delete-element {
  display: flex;
  align-items: center;
  justify-content: center;
}

.addnew-section
  .section-card
  .section-content
  .section-body
  .elements-card
  .element-title
  a.delete-element
  span {
  width: 9px;
  height: 1px;
  display: inline-block;
  background: #f44336 !important;
}

.addnew-section
  .section-card
  .section-content
  .section-body
  .elements-card:last-child {
  margin-bottom: 0;
}
.card-content.margin-box {
  margin-bottom: 28px !important;
}
/*----------------*/
.actionButton{
  width: 55px;
  height: 55px;
  position: absolute;
  top: 30%;
  background: #FFFFFF;
  border: 1px solid #cacaca;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(230, 228, 228, 0.25);
  border-radius: 3px;
}
.actionButton svg{
  font-size: 2rem;
  color: #7c7171;
}
.remove{
  margin-top: 70px;
}
.MuiCheckbox-colorSecondary.Mui-checked{
   color:#2E6ADF !important;
}

