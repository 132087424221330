.breadcrumbs-top .breadcrumb li.breadcrumb-item {}

.breadcrumbs-top .breadcrumb li.breadcrumb-item a {
    font-weight: normal;
    font-size: 12px;
    
/* identical to box height */
    color: rgba(153, 153, 153, 0.75);
    line-height: 25px;
    display: inline-block;
}

.breadcrumbs-top .breadcrumb li.breadcrumb-item.active  {
    font-weight: normal;
    font-size: 12px;
    line-height: 25px;
/* identical to box height */
    color: rgba(68, 68, 68, 0.75);
}



.breadcrumbs-top .breadcrumb li.breadcrumb-item a:hover,.breadcrumbs-top .breadcrumb li.breadcrumb-item a:focus {
    color: rgba(68, 68, 68, 0.75);
}
/*   */


