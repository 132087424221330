.header-navbar .navbar-header{
    height: 80px;
    box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.04);
}
.header-navbar .navbar-container {
    height: 80px;
}
.navbar-expand-md .navbar-collapse {
    height: 100%;
}
.header-navbar .navbar-header .navbar-brand .brand-text span:nth-child(1) {
    font-weight: bold;
    font-size: 18.0175px;
    line-height: 14px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #3D4865;
    display: block;
    text-align: left;
}

.header-navbar .navbar-header .navbar-brand .brand-text span:nth-child(2) {
    font-weight: bold;
    font-size: 28.1499px;
    line-height: 30px;
    text-transform: capitalize;
    color: #3D4865;
    display: block;
    text-align: left;
}

.header-navbar .navbar-header .navbar-brand .brand-text {
    display: inline-block;
    margin: 0;
}

.header-navbar .navbar-header .navbar-brand {
    width: 100%;
    text-align: center;
}
.header-navbar .navbar-container ul.nav li a.dropdown-user-link {
    width: 32px;
    height: 32px;
    padding: 0;
    background: #EAEAEA;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.header-navbar .navbar-container ul.nav li a.dropdown-user-link .avatar {
    width: 32px;
    height: 32px;
    border-radius: 4px;
    margin: 0 !important;
}
.header-navbar .navbar-container ul.nav li a.dropdown-user-link span.notification {
    width: 14px;
    position: relative;
}
li.dropdown.dropdown-user.nav-item {
    margin-left: 12px;
}



.avatar img {
    border-radius: 4px;
    width: 100%;
}



span.notification.notification-online i {
    position: absolute;
    right: -6px;
    top: 5px;
    width: 5px;
    height: 5px;
    border-radius: 100%;
    background: linear-gradient(0deg, #4155F8, #4155F8);
}