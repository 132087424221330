
.customer-list-head {
  background: #FAFCFF;
  border-radius: 3px;
  padding: 20px 25px;
}

.customer-list-head + .card-header {
  padding-top: 0;
}

.customer-list-head span.details {
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
/* identical to box height */
  color: #3E3E3E;
  position: relative;
}

.customer-list-head .link {
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  text-decoration-line: underline;
  color: #2E6ADF;
}

.customer-list-head .text-left span.details {
  padding-right: 12px;
  margin-right: 12px;
}

.customer-list-head .text-left span.details:before {content: "";position: absolute;right: 0;top: 10%;background: #3E3E3E;width: 1px;height: 80%;}

.customer-list-head .text-left span.details:last-child:before {
  background: transparent;
}
.edit-form .upload-block.upload-block01 {
  flex: 0 0 135px;
}
.create-content .upload-block01 .upload-img {
  background: #ffffff;
  border: 0.6px solid #e1e1e1;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgb(225 225 225 / 25%);
  border-radius: 2px;
  width: 135px;
  height: 135px;
  padding: 7px;
}
.customer-list {
  margin-bottom: 20px;
}

.customer-list .details {
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
/* identical to box height */
  color: #3E3E3E;
}
.kyc-left {margin-right: 20px;border-right: 1px solid #E8E8E8;max-width: 20%;}

.kyc-right {
    flex: 1;
}

.kyc-left label {
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
/* identical to box height */
    color: #555555;
    margin: 0;
    cursor: pointer;
}

.kyc-left .col-md-12.col-xs-12 {
    margin-bottom: 20px;
}

.kyc-left input {
    position: relative;
    top: 2px;
    margin-right: 6px;
    cursor: pointer;
}
.customer-list-head.customer-view {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.customer-view .details-box {
  flex: 1;
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
}

.customer-view .details-box .detail-box {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.customer-view .details-box .detail-box.border-r {
  padding-right: 15px;
  margin-right: 15px;
  border-right: 1px solid #BEBEBE;
}

.customer-view .details-box .detail-box .content {
  margin: 0 !important;
  min-height: initial !important;
  overflow: initial;
}

.customer-view .details-box .detail-box .content p.main {
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #070707;
  margin-bottom: 10px;
}

.customer-view .details-box .detail-box .content .view {
  padding-left: 10px;
}

.customer-view .details-box .detail-box .content .list {
}

.customer-view .details-box .detail-box .content .list span.sub {
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
/* identical to box height */
  color: #909090;
}

.customer-view .details-box .detail-box .content .list span.text {
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
/* identical to box height */
  color: #525252;
  margin-left: 3px;
}

.customer-view .details-box .detail-box .img-box {
  display: inline-block;
  width: 80px;
  height: 80px;
  margin-right: 15px;
}

.customer-view .details-box .detail-box .img-box img {
  width: 100%;
}

.customer-view .details-box .detail-box .content p.name {
  font-weight: 500;
  font-size: 15px;
  line-height: 19px;
/* identical to box height */
  color: #2E6ADF;
  margin-bottom: 10px;
}

.customer-view .details-box .detail-box .content .list span.icon {
  width: 11px;
  margin-right: 10px;
  display: inline-block;
}

.customer-view .details-box .detail-box .content .list span.para {
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
/* identical to box height */
  color: #909090;
}

.customer-view .actions {
  display: flex;
}

.customer-view .actions .btn.btn-primary {
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin-left: 8px;
}

.customer-view .actions .btn.btn-secondary {
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin-left: 8px;
  border: 1px solid #2E6ADF;
  background: transparent;
}

.customer-view .actions .btn img {
  width: 14px;
}

.customer-view .details-box .detail-box:last-child {
  border-right: none;
}

.customer-view .details-box .detail-box .content .tag {
  font-weight: normal;
  font-size: 11px;
  line-height: 14px;
/* identical to box height */
  text-align: center;
  color: #000000 !important;
  padding: 3px;
  margin-left: 15px;
}

.tag.yellow {
  background: #FFE8BB;
}

.customer-view .details-box .detail-box .content .flex-list {
  display: flex;
  align-items: flex-start;
}

.customer-view .details-box .detail-box .content .flex-list .view:first-child {border-right: 1px solid #C7C7C7;padding-right: 10px;padding-left: 0;}

.customer-view .actions .btn.btn-secondary img {
  width: auto;
}

.customer-profile-list {
  padding-right: 5px;
  border-right: 1px solid #EFEFEF;
}

.customer-profile-list ul {
  margin: 0;
  padding: 0;
}

.customer-profile-list ul li {
  list-style: none;
  display: flex;
  align-items: flex-start;
  margin-bottom: 15px;
}

.customer-profile-list ul li span.sub {
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #909090;
  min-width: 135px;
  display: inline-block;
}

.customer-profile-list ul li span.text {
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #636363;
}

.customer-profile-table {
  padding-left: 30px;
}

.customer-profile-table h4.title {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
/* identical to box height */
  color: #070707;
  margin-bottom: 15px;
}

.customer-profile-table .table-block {}

.customer-profile-table .table-block table.table {
  border: 1px solid #E2E2E2;
}

.customer-profile-table .table-block table.table tr td {
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #909090;
  border-top: none;
  vertical-align: middle;
}

.customer-profile-table .table-block table.table tr td a {
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
/* identical to box height */
  text-align: right;
  text-decoration-line: underline;
  color: #2E6ADF;
}
.edit-form .titlehead h4 {
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
/* identical to box height */
  color: #2E6ADF;
  margin: 0;
}
.edit-form .titlehead {
  display: flex;
  align-items: center;
  justify-content: space-between;
}