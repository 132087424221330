.edit-form .text-left .btn.btn-primary {
    margin-left: 0;
}
.upload-field label {
    background: #ffffff;
    border: 1px solid #e1e1e1;
    box-shadow: 0px 4px 4px rgb(225 225 225 / 25%);
    border-radius: 2px;
    margin-bottom: 25px;
    font-weight: normal;
    font-size: 12px;
    color: #9a9393;
    line-height: 40px;
    height: 40px;
    padding: 0 15px;
    width: 100%;
    cursor: pointer;
    position: relative;
}

.upload-field input[type="file"] {
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}
.flex-display.d-flex {
    align-items: center;
}

.flex-display.d-flex .form-control {
    width: auto;
    flex: 1;
    margin: 0;
}

.flex-display.d-flex label.control-label {
    margin-right: 16px;
    margin-bottom: 0;
}
.pricing-box {
    margin-top: 28px;
    background: #FFFFFF;
    border: 1px solid #E1E1E1;
    box-shadow: 0px 4px 4px rgba(239, 239, 239, 0.25);
    border-radius: 3px;
    padding: 28px;
}

.pricing-box .sub-head p {
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    color: #555555;
    margin-bottom: 12px;
}

.pricing-box .text {
    font-weight: normal;
    font-size: 12px;
    line-height: 160%;
/* or 19px */
    color: #919191;
    margin-bottom: 20px;
}

.pricing-box label.control-label.sub-head {
    margin-bottom: 12px;
}

.pricing-box table.table {
    border: 1px solid #E1E1E1;
}

.pricing-box table.table tr td {
    border-bottom: 1px solid #E1E3E1;
    border-top: none;
}

.pricing-box .col-md-3.col-xs-6 {
    position: relative;
}

.pricing-box .col-md-3.col-xs-6:before {content: "";background: #E4E4E4;position: absolute;left: 0;top: 0;width: 1px;height: 100%;}

.pricing-box .col-md-3.col-xs-6:after {content: "";background: #E4E4E4;position: absolute;right: 0;top: 0;width: 1px;height: 100%;}
.row.align-center {
    align-items: center;
}

.text-left .btn {margin-left: 0 !important;}
.border-lr {
    position: relative;
}

.border-lr:before {content: "";background: #E4E4E4;left: 0;top: 0;width: 1px;height: 100%;position: absolute;}

.border-lr:after {content: "";background: #E4E4E4;right: 0;top: 0;width: 1px;height: 100%;position: absolute;}

.row.align-start {
    align-items: flex-start;
}

.border-l {
    position: relative;
}

.border-l:before {content: "";background: #E4E4E4;left: 0;top: 0;width: 1px;height: 100%;position: absolute;}
.border-l .row.align-center {
    padding: 0 15px;
}
.pricing-box .btn.btn-secondary {
    margin-bottom: 20px;
}
.pricing-box.m-o {
    margin-top: 0;
}
.pricing-box.m-o .col-md-6.col-xs-12 {
    margin-bottom: 28px;
}

.pricing-box.m-o .col-md-12.col-xs-12.text-left .btn {
    margin: 0 !important;
}

.pricing-box.m-o .col-md-6.col-xs-12 .form-control {
    margin-bottom: 16px;
}