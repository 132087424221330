.card-header ul {
    margin: 0;
    padding: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.card-header ul li {
    list-style: none;
}

.card-header ul li a {
    padding: 12px 16px 12px;
    margin-right: 0;
    display: inline-block;
    
    
    
/* identical to box height */
    color: #95959A;
    position: relative;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
/* identical to box height */
}

.card-header ul li:first-child a {
    padding-left: 0;
}

.card-header ul li.active a, .card-header ul li a:hover,.card-header ul li a.active {
    color: #2E6ADF;
}

.card-header ul li a:before {
    content: "";
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    height: 1px;
    background: #2E6ADF;
    transition: all 0.3s ease;
    transform: scale(0);
    transform-origin: center;
}

.card-header ul li.active a:before, .card-header ul li a:hover:before,.card-header ul li a.active:before {
    transition: all 0.3s ease;
    transform: scale(1);
    transform-origin: center;
}


@media(max-width:1370px){
    .card-header ul li a {
        padding: 12px 10px 12px;
        margin-right: 0;
        font-size: 12px;
        line-height: 18px;
    }
}